.select-select {
  display: none;
  /* For when we are on a small touch device and want to use native controls */
  pointer-events: none;
  position: absolute;
  opacity: 0;
}

.select-element {
  box-sizing: border-box;

  &:after, &:before {
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;

    &:after, &:before {
      box-sizing: border-box;
    }
  }

  position: absolute;
  display: none;

  &.select-open {
    display: block;

  }
}

.select-theme-default {
  box-sizing: border-box;

  * {
    box-sizing: border-box;

    &:after, &:before {
      box-sizing: border-box;
    }
  }
}

.select.select-theme-default {
  user-select: none;
  .select-content {
		font-family: inherit;
    color: inherit;
    overflow: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px -1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 16px;
  }

  .select-options {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    margin: 0;
    padding: 0;

    .select-option {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      position: relative;
      list-style: none;
      margin: 0;
      line-height: 1.25rem;
      padding: 10px 20px;
			min-width: 175px;
      display: block;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
			&:hover {
				background: #FFF1E3;
			}
      &:first-child {
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

@media (max-width: 27rem), (max-height: 27rem) {
  .select.select-theme-default .select-content {
    max-width: 11.25rem;
    max-height: 11.25rem;
  }
}

.select-target {
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  display: inline-block;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  border-radius: 20px;
  position: relative;
  padding: 8px 30px 8px 0;
  background: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  color: #575757;
  text-decoration: none;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  &.select-open {}
  &.select-target-focused {
		outline: none;
		b {
			background: #FFF1E3;
		}
    &:focus {
			outline: none;
			b {
				background: #FFF1E3;
			}
    }
  }

  b {
		position: absolute;
    right: 10px;
    top: 50%;
		transform: translateY(-50%);
    height: 10px;
    width: 6px;
		background: #fff;
		border-radius: 50%;

    &:before,
    &:after {
			content: "";
			display: block;
			position: absolute;
			width: 7px;
			height: 2px;
			background: #575757
		}
    &:before {
			top: 50%;
			left: calc(50% - 2px);
			transform: translate(-50%,-50%) rotate(45deg);
    }
    &:after {
			top: 50%;
			left: calc(50% + 2px);
			transform: translate(-50%,-50%) rotate(-45deg);
    }
  }
	&:hover {
    b {
      &:before, &:after {
        background: $color-gold-link;
      }
    }
  }
	&.select-open {
		b {
			&:before {
				transform: translate(-50%,-50%) rotate(-45deg);
        background: $color-gold-link;
			}
			&:after {
        transform: translate(-50%,-50%) rotate(45deg);
        background: $color-gold-link;
			}
		}
	}
}

.select-box-100 {
  .select-target {
    display: block;
  }
}