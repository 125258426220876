.form-control {
  font-size: 16px;
  border-radius: 0;
  color: $color-body;
  &::placeholder {
    color: rgba(132, 132, 132, 1);
  }
  &:focus {
    border-color: $color-red;
    // box-shadow: 0 0 0 5px rgba(222, 30, 56, 0.2);

  }
}

.form-group {
  position: relative;
  input,textarea {
    position: relative;
    z-index: 1;
  }
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: 50%;
    left: 50%;
    border: 1px solid rgba(222, 30, 56, 0.2);
    transform: translate(-50%,-50%);
    opacity: 0;
  }
  &.focused {
    &:before {
      opacity: 1;
    }
  }
}

textarea {
  &.form-control {
    min-height: 130px;
    resize: none;
  }
}