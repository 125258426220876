.a_list {
  &__brands {
    overflow-x: auto;
    .item {
      width: 430px;
      min-width: 430px;
      height: 430px;
      border-right: 1px solid $color-border;
      position: relative;
      &:nth-child(1) {
        border-left: 1px solid $color-border;
      }
      &-red {
        background: $color-red;
        color: #fff;
        border-color: $color-red;
      }
      &:hover {
        background: $color-red;
        color: #fff;
        img {
          filter: brightness(0) invert(1);
        }
      }
      &-animation {
        &-logo {
          transition: 0.5s;
          img {
            transition: 0.5s;
          }
        }
        &-caption {
          position: absolute;
          opacity: 0;
          transition: 0.1s;
        }
        &:hover {
          .item-animation-logo {
            transition: 0.5s;
            margin-bottom: 80px;
            margin-left: -30px;
            margin-top: -50px;
            img {
              transition: 0.5s;
              transform: scale(0.5);
            }
          }
          .item-animation-caption {
            position: relative;
            opacity: 1;
            transition: 0.5s;
          }
        }
      }
    }
  }
}
.a_list__possib {
  position: relative;
  overflow: hidden;
  .row {
    border-left: 1px solid $color-border;
    .item-outer {
      border-bottom: 1px solid $color-border;
      border-right: 1px solid $color-border;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 8000px;
        height: 1px;
        background: $color-border;
        position: absolute;
        bottom: -1px;
        left: 0;
        transform: translateX(-50%);
      }

    }
  }
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    left: 0;
    position: absolute;
    background: $color-border;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  .item {
    min-height: 300px;
    color: #282828;
    &-red {
      border: none !important;
      background: $color-red !important;
      color: #fff !important;
    }
    .h3 {
      font-family: $font;
      line-height: 21px;
      font-weight: 700;
      margin-bottom: 25px;
      color: $color-red;
    }
    &:hover {
      background: $color-red;
      color: #fff;
      .h3, p, a {
        color: #fff;
      }
    }
  }
  &-reviews {
    padding-top: 1px;
    .item {
      cursor: pointer;
      background: #fff;
      &:hover {
        background: $color-red;
        color: #fff;
        transition: 0.3s;
        a {
          color: #fff;
        }
        .text-grey {
          color: #fff;
        }
      }
    }
  }
}

#list {
  &.openItem {
    .item-outer {
      &.d-none {
        display: flex !important;
      }
    }
    .item-outer-more {
      &.d-flex {
        display: none !important;
      }
    }

  }
}