footer {
  .a_footer__right {
    padding-right: 184px;
  }
  .cotainer {
    position: relative;
  }
  .a_module__nav {
    li:hover {
      a {
        color: #282828;
      }
    }
  }
}
a#scrollUp {
  position: fixed;
  width: 36px;
  height: 80px;
  bottom: 50px;
  right: 20px;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: $color-red;
    position: absolute;
    left: 0;
    top: 0;
    transform: skewY(20deg);
    z-index: 0;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
  }
  &:hover {
    &:before {
      background: #282828;
    }
  }
}

footer {
  .a_module__nav {
    a {
      &:hover {
        color: $color-red !important;
        &:after {
          display: none;
        }
        span {
          color: $color-red !important;
        }
      }
    }
    li {
       &.current {
        a {
           span {
             color: #848484 !important;
           }
         }
      }
    }
  }
}