.s-top {
  position: relative;
  overflow: hidden;
  padding-top: 112px;
  &-bg {
    height: 100%;
    min-height: 500px;
    width: calc(50% - 30px);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
    > div {
      position: absolute;
      width: 100%;
      right: 0;
      top: 0;
      height: 100%;
      background-position: 100% 50%;
      background-size: auto 100%;
      background-repeat: no-repeat;
      &.bg-center {
        background-position: 50% 50%;
      }
    }
    &-main {
      min-height: 720px;
      > div {
        background-position: 0 50% !important;
        background-position: 100% 50%;
        @include media('1600px') {
          background-position: 0 50%;
        }
        @include media('1400px') {
           background-position: 100% 50% !important;
         }
        @include media('1200px') {
           background-position: 100% 50%;
         }
        @include media('1090px') {
           background-position: 0% 50% !important;
         }
      }
    }
    & + .container {
      position: relative;
      z-index: 1;
    }
  }
  &-caption {
    .block__phone {
      a {
        font-size: 18px;
        color: rgba(70, 84, 98, 1);
      }
    }
    img {
      margin-left: -10px;
    }
  }
}

.s-top-caption-img {
  &.img-content {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      vertical-align: middle;
    }
  }
  &.img-cover {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.img-right {
        object-position: 100% 50%;
      }
      &.img-center {
        object-position: 50% 50%;
      }
      &.img-left {
        object-position: 0% 50%;
      }
    }
  }
}

.a_s {
  &-slogan {
    position: relative;
    overflow: hidden;
    &-img {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }
  &-brands {
    position: relative;
    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      left: 0;
      position: absolute;
      background: $color-border;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    .a_list__brands {
      display: flex;
      overflow-x: auto;
    }
  }
}

.a_s_address {
  &-row {
    a.link-email {
      &:hover {
        color: #282828;
      }
    }
  }
}