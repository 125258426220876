$font: 'Montserrat', sans-serif;
$font-title: 'Cormorant', serif;

$color-body: rgba(40, 40, 40, 1);
$modal-lg: 776px;

// custome
$color-gold-link: #9E6010;
$color-gold-bg: #D3A567;
$color-grey: rgba(132, 132, 132, 1);
$color-black: rgba(40, 40, 40, 1);

$color-green: #666B3A;
$color-red: #DE1E38;
$color-rose: #E9538D;
$color-yellow: #FBAF3F;

$color-blue: #0057B1;
$color-blue-light: #3979BD;
$color-blue-light-2: #00A8C9;
$color-blue-light-very: #D8EDFF;

$color-green-light: #A5A171;
$color-grey-light: rgba(87, 87, 87, 1);

$color-border: #E0E0E0;
$border-color: $color-border;

$border-radius: 4px;
$border-color: rgba(84, 84, 84, 0.3);

$form-text-font-size: 14px;

$btn-font-size: 14px;
$btn-padding-y: 14px;
$btn-padding-x: 25px;
$btn-focus-box-shadow: none;

$input-font-size: 14px;
$input-padding-y: 14px;
$input-padding-x: 20px;
$input-border-color: $color-border;
$input-border-radius: 5px;
$input-focus-border-color: $color-gold-bg;
$input-focus-box-shadow: none;

$form-check-input-focus-box-shadow: none;
$form-check-margin-bottom: 0;



$enable-negative-margins: true;
$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer * 1,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  );
// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 100%,
  md: 768px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;

$breadcrumb-divider: quote("/");

$accordion-padding-y: 0;
$accordion-padding-x: 0;
$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-body-padding-y: 0;
$accordion-body-padding-x: 0;
$accordion-bg: #fff;
$accordion-icon-width: 1rem;
$accordion-color: #575757;
$accordion-border-width: 0;
$accordion-button-bg: #fff;
$accordion-button-active-bg: #fff;
$accordion-button-active-color: none;
$accordion-button-focus-box-shadow: none;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;
$accordion-icon-color: $color-blue;
$accordion-icon-active-color: $color-blue;
$accordion-button-icon: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12 2V22M22 12H2' stroke='#{$color-blue;}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M22 12H2' stroke='#{$color-blue;}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");

$pagination-padding-y: .58rem;
$pagination-padding-x:  1rem;

$pagination-color: rgba(31, 28, 28, 1);
$pagination-border-color: rgba(84,84,84,1);
$pagination-border-radius: 4px;

$pagination-hover-color: rgba(31, 28, 28, 1);
$pagination-hover-bg: #fff;
$pagination-hover-border-color: #D3A567;

$pagination-focus-color: rgba(31, 28, 28, 1);
$pagination-focus-bg: #fff;
$pagination-focus-box-shadow: none;
$pagination-focus-outline: 0;

$pagination-active-bg: #fff;
$pagination-active-color: rgba(87, 87, 87, 1);
$pagination-active-border-color: $border-color;

$box-shadow-lg: 0px 10px 30px -1px rgba(0, 0, 0, 0.1);

$dropdown-border-radius: 10px;
$dropdown-border-width: 0;
$dropdown-min-width: 330px;
$dropdown-box-shadow: $box-shadow-lg;
