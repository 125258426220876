@import "../mixins/base";
@import "../mixins/media";

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: #fff;
  .container {
    position: relative;
  }
  .a_module__lang {
    position: absolute;
    top: 0;
    right: 12px;
    display: flex;
    a {
      position: relative;
      font-size: 14px;
      display: block;
      overflow: hidden;
      width: 30px;
      height: 120px;
      box-sizing: border-box;
      text-align: center;
      padding: 68px 0 35px 0;
      color: #fff;
      margin-left: 10px;
      overflow: hidden;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 160px;
        bottom: 10px;
        transform: skewY(-20deg);
        position: absolute;
        left: 0;
        background: $color-red;
        z-index: 0;
      }
      span {
        display: inline-block;
        position: relative;
        z-index: 1;
      }
      &:nth-child(2) {
        margin-top: -30px;
      }
      &:hover {
        color: #fff;
        text-decoration: none;
        &:after {
          background: $color-black;
        }
      }
    }
  }
  .open_nav {
    width: 42px;
    height: 42px;
    position: relative;
    i {
      background: #282828;
      position: absolute;
      display: block;
      width: 24px;
      height: 2px;
      left: 8px;
      transition: 0.3s;
      &:nth-child(1) {
        top: 10px;
      }
      &:nth-child(2) {
        top: 19px;
      }
      &:nth-child(3) {
        top: 28px;
      }
    }
  }
}
body {
  padding-top: 0;
  box-sizing: border-box;
  @include media('576px') {
    padding-top: 0;
  }
  &[class*=scroll] {
    header {
      box-shadow: 0 0 4px rgba(0,0,0,.2);
    }
  }
}
.body-nav-is-open {
  @include media('992px') {
    overflow: hidden;
  }
 .open_nav {
    i {
      &:nth-child(1) {
        top: 19px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        top: 10px;
        opacity: 0;
      }
      &:nth-child(3) {
        top: 19px;
        transform: rotate(-45deg);
    }
    }
  }
}

.a_module__nav {
  a {
    display: inline-block;
    position: relative;
    color: #282828;
    &:after {
      content: "";
      display: block;
      width: 16px;
      height: 46px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) skewY(20deg);
      // background: rgba(33,225,199,1);
      // mix-blend-mode: difference;
      z-index: 0;
      opacity: 0;
      background: $color-red;
    }
    span {
      display: inline-block;
      position: relative;
      z-index: 1;
    }
  }
  // li.current,
  li:hover {
    a {
      span {
        color: #282828;
      }
      &:after {
        opacity: 1;
      }
    }
  }
}

:root {
	--bar-bg: #212529;
}

.menu-icon {
	position: relative;
	width: 50px;
	height: 50px;
	cursor: pointer;
	.menu-icon__cheeckbox {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		cursor: pointer;
		z-index: 2;
		-webkit-touch-callout: none;
		position: absolute;
		opacity: 0;
	}
	div {
		margin: auto;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 22px;
		height: 12px;
	}
	span {
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background-color: var(--bar-bg, #000);
		border-radius: 1px;
		transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

		&:first-of-type {
			top: 0;
		}
		&:last-of-type {
			bottom: 0;
		}
	}
	&.active,
	.menu-icon__cheeckbox:checked + div {
		span {
			&:first-of-type {
				transform: rotate(45deg);
				top: 5px;
			}
			&:last-of-type {
				transform: rotate(-45deg);
				bottom: 5px;
			}
		}
	}

	&.active:hover span:first-of-type,
	&.active:hover span:last-of-type,
	&:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
	&:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
		width: 22px;
	}

	&:hover {
		// no need hover effect on mobile.
		@media (min-width: 1024px) {
			span:first-of-type {
				width: 26px;
			}

			span:last-of-type {
				width: 12px;
			}
		}
	}
}

a.scrollTo {
  cursor: pointer;
}