@keyframes "splide-loading" {
	0% {
		transform: rotate(0);
	}
	to {
		transform: rotate(1turn);
	}
}
.splide--draggable {
	>.splide__slider {
		>.splide__track {
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
	>.splide__track {
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}
.splide--fade {
	>.splide__slider {
		>.splide__track {
			>.splide__list {
				display: block;
				>.splide__slide {
					left: 0;
					opacity: 0;
					position: absolute;
					top: 0;
					z-index: 0;
				}
				>.splide__slide.is-active {
					opacity: 1;
					position: relative;
					z-index: 1;
				}
			}
		}
	}
	>.splide__track {
		>.splide__list {
			display: block;
			>.splide__slide {
				left: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				z-index: 0;
			}
			>.splide__slide.is-active {
				opacity: 1;
				position: relative;
				z-index: 1;
			}
		}
	}
}
.splide--rtl {
	direction: rtl;
}
.splide--ttb.is-active {
	>.splide__slider {
		>.splide__track {
			>.splide__list {
				display: block;
			}
		}
	}
	>.splide__track {
		>.splide__list {
			display: block;
		}
	}
}
.splide__container {
	box-sizing: border-box;
	position: relative;
}
.splide__list {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin: 0 !important;
	padding: 0 !important;
	transform-style: preserve-3d;
}
.splide.is-initialized {
	&:not(.is-active) {
		.splide__list {
			display: block;
		}
	}
	visibility: visible;
}
.splide__pagination {
	-ms-flex-align: center;
	align-items: center;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0;
	pointer-events: none;
	li {
		display: inline-block;
		line-height: 1;
		list-style-type: none;
		margin: 0;
		pointer-events: auto;
	}
}
.splide__progress__bar {
	width: 0;
}
.splide {
	outline: none;
	position: relative;
	visibility: hidden;
}
.splide.is-rendered {
	visibility: visible;
}
.splide__slide {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	box-sizing: border-box;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	list-style-type: none !important;
	margin: 0;
	outline: none;
	position: relative;
	img {
		vertical-align: bottom;
	}
}
.splide__slider {
	position: relative;
}
.splide__spinner {
	animation: splide-loading 1s linear infinite;
	border: 2px solid #999;
	border-left-color: transparent;
	border-radius: 50%;
	bottom: 0;
	display: inline-block;
	height: 20px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}
.splide__track {
	overflow: hidden;
	position: relative;
	z-index: 0;
}
