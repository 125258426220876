/*
  SASS variables are information about icon's compiled state, stored under its original file name

  .icon-home
    width: $icon-home-width

  The large array-like variables contain all information about a single icon
  $icon-home: x y offset_x offset_y width height total_width total_height image_path

  At the bottom of this section, we provide information about the spritesheet itself
  $spritesheet: width height image $spritesheet-sprites
  */
$icon-3-name: 'icon-3';
$icon-3-x: 0px;
$icon-3-y: 0px;
$icon-3-offset-x: 0px;
$icon-3-offset-y: 0px;
$icon-3-width: 52px;
$icon-3-height: 52px;
$icon-3-total-width: 52px;
$icon-3-total-height: 52px;
$icon-3-image: './../img/sprites.png';
$icon-3: (0px, 0px, 0px, 0px, 52px, 52px, 52px, 52px, './../img/sprites.png', 'icon-3', );
$spritesheet-width: 52px;
$spritesheet-height: 52px;
$spritesheet-image: './../img/sprites.png';
$spritesheet-sprites: ($icon-3, );
$spritesheet: (52px, 52px, './../img/sprites.png', $spritesheet-sprites, );

/*
  The provided mixins are intended to be used with the array-like variables

  .icon-home
    @include sprite-width($icon-home)

  .icon-email
    @include sprite($icon-email)
  */
@mixin sprite-width($sprite) {
  width: nth($sprite, 5); }

@mixin sprite-height($sprite) {
  height: nth($sprite, 6); }

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y; }

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image}); }

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite); }

/*
  The `sprites` mixin generates identical output to the CSS template
    but can be overridden inside of SASS

  @include sprites($spritesheet-sprites)
  */
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      &::before {
        content: '';
        display: inline-block;
        @include sprite($sprite); } } } }
