@mixin media($width) {
  @media (max-width: $width) {
    @content;
  }
}

@include media('1460px') {

}
@include media('1400px') {
  .h1, h1 {
    font-size: 52px;
    line-height: 62px;
  }
  .a_list__brands {
    .item {
      min-width: 33.3333%;
      width: 33.3333%;
      height: 360px;
      min-height: 360px;
    }
  }
}
@include media('1200px') {
  .h1, h1, .h1,
  .h2, h2, .s .h2, h2 {
    font-size: 40px;
    line-height: 52px;
  }
  .a_s-slogan {
    .text-36 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .a_list__possib {
    .item {
      min-height: 430px;
    }
  }
  .s-top-bg > div{
    background-position: 100% 50%;
  }
}
@include media('992px') {
  .text-18 {
    font-size: 16px;
  }
  .text-36 {
    font-size: 24px;
    line-height: 31px;
  }
  body, p, li {
    font-size: 16px;
    line-height: 22px;
  }
  article {
    p, li {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 15px;
    }
    li {
      &::before {
        top: 5px;
      }
    }
  }
  .a_module__logo {
    img {
      max-width: 155px;
    }
  }
  .s-top {
    min-height: auto;
    height: auto;
  }
  .a_s-brands {
    .a_list {
      &__brands {
        .item {
          width: 245px;
          min-width: 245px;
          height: 232px;
          min-height: 232px;
          &:nth-child(2) {
            border-left: 1px solid #E0E0E0;
          }
        }
      }
    }
  }

  footer {
    .a_footer__right {
      padding-right: 74px;
    }
  }
  #nav {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    height: calc(100vh - 100px);
    background: #fff;
    li {
      margin-bottom: 50px;
    }
    a {
      font-size: 24px;
    }
  }
}
@include media('768px') {
  .s-top-bg {
    min-height: auto;
    > div {
      position: relative;
    }
  }
  .n-ml-12 {
    margin-left: -12px;
  }
  .s-top-caption-img {
    margin-left: -12px;
  }
}
@include media('568px') {
  #nav {
    top: 70px;
    height: calc(100% - 70px);
  }
  .s-top {
    .btn {
      width: 100%;
    }
  }
  .a_list__possib {
    &-inner {
      overflow-x: auto;
    }
  }

  .modal {
    .btn-close {
      top: 10px;
      right: 10px;
    }
    .btn {
      width: 100%;
    }
  }

  .btn-sm-100 {
    width: 100%;
  }
  .a_list__possib .row .item-outer::before{
    display: none;
  }
}
@include media('468px') {

}
@include media('380px') {
  .main {
    overflow: hidden;
  }
  .form-control {
    padding: 10px 14px;
  }
  textarea.form-control {
    min-height: 80px;
  }
  .btn {
    height: 60px;
    line-height: 60px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .text-16 {
    font-size: 14px;
  }
  .modal {
    .h1 {
      font-size: 30px;
      line-height: 36px;
    }
    .mb-2 {
      margin-bottom: 10px !important;
    }
    .mb-4 {
      margin-bottom: 20px !important;
    }
  }
}