// @import "../../local_modules/normalize.css/normalize";

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #fff;
  font-size: 1.125rem;
  line-height: 27px;
  font-family: $font;
  color: $color-body;
  * {
    box-sizing: border-box;
    &::selection {
      background: $color-red;
      color: #fff;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  font-family: $font-title;
  letter-spacing: -0.5px;
}

.h1, h1 {
  font-size: 70px;
  line-height: 91px;
}

.h2, h2, .s .h2, h2 {
  font-size: 70px;
  line-height: 91px;
}
.h3, h3, .s .h3, h3 {
  font-size: 24px;
  line-height: 32px;
}
.h1-font, .h2-font, .h3-font, .h4-font, .h5-font, .h6-font {
  font-family: $font;
  font-weight: 700;
}
.text-400 {
  font-weight: 400 !important;
}

a {
  color: $color-red;
  text-decoration: none;
  &:hover {
    color: $color-black;
    text-decoration: none;
  }
  &.link-black {
    color: $color-black;
    text-decoration: none;
  }
  &:hover {
    color: $color-red;
    text-decoration: none;
  }
}

.text-grey {
  color: $color-grey;
}

.text-14 {
  font-size: 14px !important;
  line-height: 21px;
}
.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
.text-20 {
  font-size: 20px;
}
.text-22 {
  font-size: 22px;
}
.text-24 {
  font-size: 24px !important;
  line-height: 31px !important;
}
.text-36 {
  font-size: 36px;
  line-height: 46px;
}

nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0;
      padding: 0;
    }
  }
}

header, footer {
  nav {
    a {
      color: $color-body;
      &:hover {
        color: $color-red;
      }
    }
  }
}

.d-none {
  &.show {
    display: block !important;
  }
}

.open_more {
  span {
    &:nth-child(2) {
      display: none;
    }
  }
  &[aria-expanded="true"] {
    i {
      transform: rotate(180deg);
    }
    span {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
}
.modal {
  .btn-close {
    background: none;
    opacity: 10;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.icon-scroll {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  width: 42px;
  height: 42px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &-block {
    position: relative;
  }
}

#scrollUp {
  opacity: 0;
  transition: 0.3s;
}
.btnScrollUpVisible {
  #scrollUp {
    opacity: 1;
  }
}

.icon-scroll-block {
  .icon-scroll {
    opacity: 0.5;
  }
  &.left {
    .icon-scroll {
      opacity: 0;
      transition: 0.3s;
    }
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg)
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg)
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg)
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg)
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

.animate__headShake {
  animation: 2s linear 1s infinite reverse headShake;
}