
.btn {
  border-radius: 0;
  font-size: 16px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 14px);
    top: 50%;
    left: 50%;
    border: 1px solid rgba(222, 30, 56, 0.2);
    transform: translate(-50%,-50%);
    opacity: 0;
  }
  &:hover {
    text-decoration: none;
  }
  &-red {
    padding: 0 50px;
    background: $color-red;
    color: #fff;
    height: 87px;
    line-height: 87px;
    text-align: center;
    white-space: nowrap;
    &:hover {
      background: $color-black;
      color: #fff;
    }
    &:focus {
      &:before {
        opacity: 1;
      }
    }
  }
  &-link {
    text-decoration: none;
    color: $color-red;
    padding-left: 0;
    padding-right: 0;
    height: auto;
    line-height: normal;
    &:hover {
      color: $color-black;
    }
  }
}
.icon-svg {
  min-width: 24px;
  min-height: 24px;
  svg {
    transform: translate(-50%,-50%);
  }
}
.icon-share {
  width: 32px;
  height: 32px;
  position: relative;
}
.icon-border-grey {
  border: 1px solid #848484;
  svg {
    stroke: #848484;
  }
  &:hover {
    border-color: $color-red;
    svg {
      stroke: $color-red;
    }
  }
}

.link-share {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  &:before {
    content: "";
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: 50%;
    left: 50%;
    border: 1px solid rgba(222, 30, 56, 0.2);
    transform: translate(-50%,-50%);
    opacity: 0;
  }
  &:focus {
    &:before {
      opacity: 1;
    }
  }
}