header {
  z-index: 10;
}
main {
  position: relative;
  z-index: 0;
}

article {
  p,li {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin: 0 0 20px 0;
      padding-left: 55px;
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        background: $color-red;
        position: absolute;
        left: 30px;
        top: 8px;
        transform: skewY(20deg);
      }
    }
  }
}

.text-404 {
  font-family: $font-title;
  font-size: 300px;
  line-height: 390px;
  font-style: normal;
  font-weight: 600;
}

blockquote {
    padding: 0px 0px 0px 20px;
    position: relative;
    border-left: 5px rgb(222, 30, 56) solid;
}